<template>
<div id="page" class="my-partners-index">
  <div class="mpi-statistical-part" v-loading="countLoading">
    <statistical :data="statisticalData" />
  </div>
  <div class="mpi-list-part">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-form-item prop="keyword">
        <el-input v-model="form.keyword" placeholder="姓名/联系方式" clearable>
          <template #prepend>
            <i class="iconfont icon-sousuo" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="dateRange">
        <el-date-picker value-format="YYYY-MM-DD" v-model="form.dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
      </el-form-item>
      <el-form-item prop="is_sign">
        <el-checkbox v-model="form.is_sign">只看未签约</el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList()">搜索</el-button>
        <el-button type="info" @click="reset()">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" v-loading="tabelLoading">
      <el-table-column prop="txt_realname" label="姓名" align="center" />
      <el-table-column prop="bankCardNo" label="银行卡号" align="center" />
      <el-table-column prop="identityCardNo" label="身份证号" align="center" />
      <el-table-column prop="txt_mobile" label="预留手机号" align="center" />
      <el-table-column prop="isSignContract" label="是否签约" align="center">
        <template #default="scope">
          <template v-if="scope.row.isSignContract==1">已签约</template>
          <template v-else>未签约</template>
        </template>
      </el-table-column>
      <el-table-column prop="int_agent_levels" label="代理级别" align="center">
        <template #default="scope">
          <template v-if="formatLevel(scope.row.int_agent_levels).length>10">
            <el-tooltip class="box-item" effect="dark" :content="formatLevel(scope.row.int_agent_levels)" placement="top">
              {{formatLevel(scope.row.int_agent_levels).slice(0, 10) + "..."}}
            </el-tooltip>
          </template>
          <template v-else>{{formatLevel(scope.row.int_agent_levels)}}</template>
        </template>
      </el-table-column>
      <el-table-column prop="dt_create" label="创建时间" align="center"></el-table-column>
      <el-table-column label="操作" align="center" class-name="table-option">
        <template #default="scope">
          <div class="table-icon" @click="checkDetail(scope.row)">
            <el-icon>
              <Document />
            </el-icon> 详情
          </div>
          <div class="table-icon" v-if="scope.row.isSignContract!=1" @click="signContract(scope.row)">
            <el-icon>
              <EditPen />
            </el-icon>
            签署
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" @sizeChange="(e) => {form.limit=e;getList()}" @pageChange="(e) => {form.page=e;getList()}" />
  </div>
  <contract v-if="contractShow" :bizUserId="bizUserId" @close="()=>{contractShow=false;bizUserId='';getList();getCount();}" />
  <detail v-if="detailShow" :detailData="detailData" @close="()=>{detailShow=false;detailData=new Object();}" />
</div>
</template>

<script>
// 合伙人管理 - 我的合伙人
// is_sign 选中0 未选中不传
import { computed, onMounted, reactive, toRefs, ref } from 'vue';
import { Statistical, Pagination } from "components";
import { Document, EditPen } from "@element-plus/icons-vue";
import { getPartners, partnersCount } from "api/apis.js";
import { ElMessage, FormInstance } from 'element-plus';
import Contract from "./comps/Contract.vue";
import Detail from "./comps/Detail.vue";
export default {
  components: {
    Statistical,
    Document,
    EditPen,
    Pagination,
    Contract,
    Detail
  },
  setup() {
    const state = reactive({
      formRef: ref(FormInstance),
      tabelLoading: false,
      countLoading: false,
      statisticalData: [
        { key: "cnt_allpartner", end: "人", value: 0, zn: "合伙人总数", eg: "Number of partners" },
        { key: "cnt_unsigned", end: "人", value: 0, zn: "待签约数量", eg: "Quantity to be signed" },
        { key: "cnt_signed", end: "人", value: 0, zn: "已签约数量", eg: "Quantity signed" }
      ],
      form: {
        keyword: null,
        dateRange: [],
        is_sign: false,
        page: 1,
        limit: 10
      },
      total: 0,
      tableData: [],
      bizUserId: "",
      contractShow: false,
      detailShow: false,
      detailData: {}
    });

    // 计算代理级别
    const formatLevel = computed(() => {
      return (param) => {
        let result = [];
        if (param.includes(",")) {
          param = param.split(",");
        } else {
          param = param.split("");
        }
        result = arrayCircu(param);
        if (result.length > 1) {
          result = result.map(t => t).join("、");
        } else {
          result = result.toString();
        }
        return result;
      }
    })

    // 循环数组
    const arrayCircu = (param) => {
      let result = [];
      for (let i = 0; i < param.length; i++) {
        if (param[i] == "1") {
          result.push("省级合伙人");
        } else if (param[i] == "2") {
          result.push("市级合伙人");
        } else if (param[i] == "3") {
          result.push("市场专员");
        } else {
          result.push("诊所负责人");
        }
      }
      return result;
    }

    // 获取列表数据
    const getList = () => {
      let param = {
        keyword: (state.form.keyword || "").trim() == "" ? null : state.form.keyword,
        is_sign: state.form.is_sign ? 1 : null,
        dt_from: null,
        dt_to: null,
        page: state.form.page,
        limit: state.form.limit
      }
      if ((state.form.dateRange || []).length > 0) {
        param.dt_from = state.form.dateRange[0];
        param.dt_to = state.form.dateRange[1];
      }
      let keys = Object.keys(param);
      for (let i = 0; i < keys.length; i++) {
        if (param[keys[i]] == null) {
          delete param[keys[i]];
        }
      }
      state.tabelLoading = true;
      getPartners(param).then(response => {
        if (response.code == 200) {
          if (response.data) {
            state.tableData = response.data.data;
            state.total = response.data.row;
          } else {
            state.tableData = [];
            state.total = 0;
          }
        } else {
          state.tableData = [];
          state.total = 0;
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.tabelLoading = false;
      })
    };

    // 合伙人统计
    const getCount = () => {
      state.countLoading = true;
      partnersCount().then(response => {
        if (response.code == 200) {
          let keys = Object.keys(response.data);
          for (let i = 0; i < keys.length; i++) {
            let index = state.statisticalData.findIndex(t => t.key == keys[i]);
            if (index != -1) {
              state.statisticalData[index].value = response.data[keys[i]];
            }
          }
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.countLoading = false;
      })
    };

    // 重置表单
    const reset = () => {
      state.formRef.resetFields();
      getList();
    };

    // 查看详情
    const checkDetail = (item) => {
      state.detailData = item;
      state.detailShow = true;
    };

    // 签署合同
    const signContract = (item) => {
      state.bizUserId = item.bizUserId;
      state.contractShow = true;
    }

    onMounted(() => {
      getList();
      getCount();
    })

    return {
      ...toRefs(state),
      getList,
      formatLevel,
      arrayCircu,
      getCount,
      reset,
      checkDetail,
      signContract
    };
  }
}
</script>

<style lang="scss" scoped>
.my-partners-index {
  .mpi-list-part {
    margin-top: 24px;
    height: calc(100% - 150px);

    .el-table {
      height: calc(100% - 100px);
    }
  }
}
</style>
