<template>
<el-dialog v-model="visible" title="签署合同" width="80%" :before-close="close" :close-on-click-modal="false">
  <div v-loading="dialogLoading" class="my-iframe">
    <iframe :src="url" scrolling="auto" frameborder="1" />
  </div>
  <template #footer>
    <div class="dialog-footer">
      <el-button @click="close" type="info">关 闭</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 签署合同
import { onMounted, reactive, toRefs } from 'vue';
import { goSign } from "api/apis.js";
import { ElMessage } from 'element-plus';
export default {
  props: {
    bizUserId: { type: String },
  },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      dialogLoading: false,
      url: ""
    });

    onMounted(() => {
      getContractUrl();
    });

    // 获取合同链接
    const getContractUrl = () => {
      state.dialogLoading = true;
      goSign({
        bizUserId: prop.bizUserId
      }).then(response => {
        if (response.code == 200) {
          state.url = response.data.signUrl;
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.dialogLoading = false;
      })
    }

    const close = () => {
      ctx.emit("close");
    }

    return {
      ...toRefs(state),
      close,
      getContractUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.my-iframe {
  height: 100%;

  iframe {
    height: 100%;
    width: 100%;
  }
}
</style>
