<template>
<el-dialog v-model="visible" :title="title" :before-close="close">
  <div class="dialog-body my-partners-detail" v-loading="loading">
    <div class="mpd-title">基本信息</div>
    <table class="mpd-base-info-table my-table">
      <tr>
        <th>姓名</th>
        <td>{{detailData.txt_realname}}</td>
        <th>预留手机号</th>
        <td>{{detailData.txt_mobile}}</td>
      </tr>
      <tr>
        <th>身份证号</th>
        <td colspan="3">{{detailData.identityCardNo}}</td>
      </tr>
      <tr>
        <th>银行卡号</th>
        <td colspan="3">{{detailData.bankCardNo}}</td>
      </tr>
      <tr>
        <th>开户行信息</th>
        <td colspan="3">{{detailData.txt_deposit_bank}}</td>
      </tr>
      <tr>
        <th>是否签约</th>
        <td>
          <template v-if="detailData.isSignContract==1">已签约</template>
          <template v-else>未签约</template>
        </td>
        <th>创建时间</th>
        <td>{{detailData.dt_create}}</td>
      </tr>
    </table>
    <div class="mpd-title">代理级别信息</div>
    <table class="my-table">
      <tr>
        <th>代理级别</th>
        <th>代理地区</th>
        <th>代理时间</th>
      </tr>
      <tr v-for="(item, index) in levelData" :key="index">
        <td>{{item.txt_agent_level}}</td>
        <td>{{item.txt_area_name}}</td>
        <td>{{item.dt_agent||"- -"}}</td>
      </tr>
    </table>
  </div>
  <template #footer>
    <div class="dialog-footer">
      <el-button @click="close" type="info">关 闭</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 合伙人详情
import { onMounted, reactive, toRefs } from 'vue';
import { getPartnerInfoById } from "api/apis.js";
import { ElMessage } from 'element-plus';
export default {
  props: { detailData: { type: Object } },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      loading: false,
      levelData: [],
      title: "合伙人详情"
    });

    const close = () => {
      ctx.emit("close");
    };

    // 获取详情
    const getInfo = () => {
      state.loading = true;
      getPartnerInfoById({
        txt_wechat_pb_auth: prop.detailData.txt_wechat_pb_auth
      }).then(response => {
        if (response.code == 200) {
          state.levelData = response.data;
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.loading = false;
      })
    };

    onMounted(() => {
      getInfo();
      state.title = "合伙人【" + prop.detailData.txt_realname + "】详情";
    })

    return {
      ...toRefs(state),
      close,
      getInfo
    }
  }
}
</script>

<style lang="scss" scoped>
.my-partners-detail {
  .mpd-title {
    font-weight: bold;
    font-size: 16px;
    margin: 10px 0;

    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
